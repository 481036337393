import { createI18n } from 'vue-i18n'
import en from './idiomas/en.json'
import es from './idiomas/es.json'
import ca from './idiomas/ca.json'

function loadLocaleMessages(){
    const locales =[{English:en},{Castellano:es},{Català:ca}]
    const messages = {}

    locales.forEach(lang => {
        const key = Object.keys(lang)
        messages[key] = lang[key]
    })
    return messages
}

export default createI18n({
    locale: 'Català',
    fallbackLocale: 'Castellano',
    localeAbr: 'ca',
    messages: loadLocaleMessages(),
    warnHtmlInMessage: 'off' // disable of the Detected HTML in message
})