<template>
  <div>
    <div class="fonsBlanc_light">
    <div class="container" style="margin-top: 0px;">
      <div class="row" >
        <div class="col-lg-12">
          {{$t('__sou_a__')}}: <a class="breadcrumb" @click="redireccionInicio()">{{$t('Inici')}}</a>&nbsp;>&nbsp;{{$t('Resultats de la cerca')}}
       
          <div v-if="dadesPersona">
            <div class="col-12 contenidorTitolSeccio">
              <div class="titolSeccio" style="color:#000;">{{$t('Jubilats')}}</div>          
            </div>          
            <div class="col-12 contenidorTitolSeccio">
              <div v-if="dadesPersona.aliascognoms_ac" class="titolSeccio" style="color:#000;">{{dadesPersona.aliascognoms_ac}}</div>
              <div v-else class="titolSeccio" style="color:#000;font-size: 1.3em;">{{dadesPersona.nomcognoms_ac}}</div>
              <hr style="margin-top:0px;margin-left:0px;" >
            </div>
            
            <div class="col-12" style="padding-left:15px;margin-bottom:10px;margin-top:0px;">
              
              <div v-if="dadesPersona.correu_domini_txt" class="col-12"  style="margin-bottom:10px">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="40" fill="rgb(158, 158, 158)" class="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                </svg>
                <span style="margin-left:15px; margin-right:-2px;">{{dadesPersona.correu_usuari_txt}}</span>        
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentcolor" class="bi bi-at bi-gris" viewBox="0 0 16 16">
                  <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"/>
                </svg><span style="margin-left:-3px;">{{dadesPersona.correu_domini_txt}}</span>
              </div>

              <div v-if="dadesPersona.tipus_personal_s=='PAS'" class="col-lg-12" style="padding-left:0px;margin-bottom:14px">
                <h6><b>{{$t('__pas__')}}</b><br></h6>                          
              </div>
              <div v-if="dadesPersona.tipus_personal_s=='PDI'" class="col-lg-12" style="padding-left:0px;margin-bottom:14px">              
                <h6><b>{{$t('__pdi__')}}</b><br></h6>                            
              </div>
                          
              <div class="row" v-if="dadesPersona.any_jubilacio_txt" style="margin-bottom: 12px;">              
                <div class="col-lg-4" style="padding-left:11px;">
                  <span class="descripcioDades" >{{$t('__any_jubilacio__')}}</span>
                </div>
                <div class="col-lg-8" >
                  <span>{{dadesPersona.any_jubilacio_txt}}</span>
                </div>        
              </div>

              <div class="row" v-if="dadesPersona['unitat_org'+idioma+'_ac']" style="margin-bottom: 12px;">              
                <div class="col-lg-4" style="padding-left:11px;">
                  <span class="descripcioDades" >{{$t('__unitat_adscripcio_abans_jubilacio__')}}</span>
                </div>
                <div class="col-lg-8" >
                  <span>{{dadesPersona['unitat_org'+idioma+'_ac']}}</span>
                </div>        
              </div>        
              
              <div class="row" v-if="dadesPersona.unitats_vinculacio">
                <div class="col-lg-4" style="padding-left:11px;">
                  <span class="descripcioDades" style="margin-bottom: 0px;">{{$t('__centres_vinculacio_abans_jubilacio__')}}</span>
                </div>              
                <div class="col-lg-8" style="margin-bottom:10px;margin-top:0px;">
                  <div v-if="Array.isArray(dadesPersona.unitats_vinculacio)">
                    <div v-for="vinculacio in dadesPersona.unitats_vinculacio" :key="vinculacio.id">
                      <div v-if="vinculacio['unitat_vinculacio'+idioma+'_ac']">
                        <span>{{vinculacio['unitat_vinculacio'+idioma+'_ac']}}<br></span>
                      </div>                      
                    </div>            
                  </div>
                  <div v-else>
                    <div v-if="dadesPersona.unitats_vinculacio['unitat_vinculacio'+idioma+'_ac']">
                        <span>{{dadesPersona.unitats_vinculacio['unitat_vinculacio'+idioma+'_ac']}}<br></span>
                    </div>
                  </div>
                </div> 
              </div>  
              
              <div class="row" v-if="dadesPersona['area_coneixement'+idioma+'_ac']" style="margin-bottom: 12px;">
                <div class="col-lg-4" style="padding-left:11px;">
                  <span class="descripcioDades" >{{$t('__area_coneixement__')}}</span>
                </div>
                <div class="col-lg-8" >
                  <span>{{dadesPersona['area_coneixement'+idioma+'_ac']}}<br></span>
                </div>              
              </div>

              <div class="row" v-if="dadesPersona['ambit_coneixement'+idioma+'_ac']" style="margin-bottom: 12px;">
                <div class="col-lg-4" style="padding-left:11px;">
                  <span class="descripcioDades" >{{$t('__ambit_coneixement__')}}</span>
                </div>
                <div class="col-lg-8" >
                  <span>{{dadesPersona['ambit_coneixement'+idioma+'_ac']}}<br></span>
                </div>              
              </div>                 
              
              <div v-if="dadesPersona.tipus_personal_s=='PDI'" class="row">
                <div v-if="dadesPersona.urlfutur_txt" class="col-12" style="padding-left: 11px;margin-bottom:10px;">
                  <a :href="dadesPersona.urlfutur_txt+'?locale='+this.idiomaUrl" target="_blank" class="descripcioDades">{{$t('__futur__')}}</a><a :href="dadesPersona.urlfutur_txt+'?locale='+this.idiomaUrl" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a>
                </div>        

                <div class="col-12" style="padding-left: 11px;">
                  <a :href="'https://apren.upc.edu/'+idiomaUrl+'/professorat/'+dadesPersona.idgauss_s" target="_blank" class="descripcioDades">{{$t('__apren__')}}</a><a :href="'https://apren.upc.edu/'+idiomaUrl+'/professorat/'+dadesPersona.idgauss_s" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a>
                </div> 
              </div>      
            </div>  

          </div>

          <div v-if="dadesPersona==null" style="margin-top:20px;">            
            <h3>{{$t('__sense_coincidencies__')}}</h3>          
          </div>

        </div>      
      </div>
    </div>
  </div>
  <PeuPagina v-if="endFetch"/>
  </div>

</template>

<script>

const idiomas = {English:"EN",Castellano:"ES",Català:"CA"};
import PeuPagina from "@/components/PeuPagina.vue"
export default {
  components:{
    PeuPagina       
  },

  data: function () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      apiURL: 'api',
      dadesPersona: [],
      idioma: idiomas[this.$i18n.locale], //Utilizado para el idioma de los datos cargados de solr,
      idiomaUrl: "",
      idiomaPagina: this.$i18n.locale,
      id: this.$route.params.id,
      urlFutur: "",
      endFetch: false
    }
  },

  created() {  
    this.fetchData();
  },

  watch: { 
    '$i18n.locale': {
      handler: function() {
        this.cambiarIdiomaVariables()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    redireccionInicio(){
      this.$router.push({ path: '/' })
    },
    cambiarIdioma(idioma) {
      this.$i18n.locale = idioma
      this.idiomaPagina = this.$i18n.locale
    },
    cambiarIdiomaVariables() {
      this.idioma = idiomas[this.$i18n.locale]
      this.idiomaUrl = this.idioma.toLowerCase().trim()
    },

    fetchData: async function () {
      try {
        await Promise.all([
          this.fetchSolrData(),
          // this.fetchSolrCarrecs(),
          // this.fetchFoto()
        ])
      } catch (error) {
        console.log(error)
      }
    },

    fetchSolrData: function () {
      fetch(`${this.backendURL}/${this.apiURL}/jubilat?id=${this.id}`)
          .then(r => r.json())
          .then(json => {
            if(json?.response?.numFound==0){
              this.dadesPersona=null
            }
            else{
              this.dadesPersona = json.response.docs[0]
            }             

            setTimeout(() => {this.endFetch = true;}, "50");        
          })
          .catch(error => console.error('Error retrieving grupodocumentos1: ' + error))
    },
  },
}
</script>

<style>

  .open{
    background-color: green;
  }

  .closed{
    background-color: red;
  }
  .bi-gris{
    color: rgb(158, 158, 158);
  }

</style>