<template>
  <div>
    <div class="row" style="margin-left:20px;margin-bottom:10px;margin-top:0px;">
      <div class="col-lg-6" style="padding-left: 0px;margin-bottom:0px;margin-top:0px;">
        <a  v-if="carrec['descripcio'+idioma+'_ac']" class="no-link link-level-one" @click="getDadesPersona(carrec.idgauss_s)">
          {{carrec.cognomsnom_ac}}           
        </a><br>
        <a class="no-link" @click="getDadesCarrec(carrec.id)" >
          {{carrec['descripcio'+idioma+'_ac']}}          
        </a>            
      </div>
      
      <div class="col-lg-6" style="margin-bottom:0px;margin-top:-5px;color:#212529b8;padding-left: 0px;">
        <div v-if="carrec.correu_usuari_txt && carrec.correu_domini_txt" style="padding-left: 0px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="40" fill="rgb(158, 158, 158)" class="bi bi-envelope" viewBox="0 0 16 16">
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
          </svg>
          <span style="margin-left:5px; margin-right:-2px;">{{carrec.correu_usuari_txt}}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentcolor" class="bi bi-at bi-gris" viewBox="0 0 16 16">
            <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"/>
          </svg><span style="margin-left:-3px;">{{carrec.correu_domini_txt}}</span>                    
        </div>
        <div v-else class="col-lg-6" style="min-height:20px;">
          <div class="d-none d-lg-block col-lg-6" style="min-height:40px;">     
          </div>               
        </div>
        <div style="margin-top:-5px;">
          <span v-if="carrec['unitat'+idioma+'_ac']" class="" >
            {{carrec['unitat'+idioma+'_ac']}}                      
          </span> 
        </div>                
      </div>          
    </div>
  </div>
</template>
<script>
import { toRefs , computed} from "vue";
import { useRouter } from 'vue-router'

export default {
  name: "Carrec",
  props: {
    dadesCarrec: Object,
    idiomaPagina: String
  },

  setup(props) {
      const { idiomaPagina , dadesCarrec } = toRefs(props);

      const router = useRouter()

      const carrec = computed(() => {
        return dadesCarrec?.value
      });

      const idioma = computed(() => {
          return idiomaPagina?.value
      });

      function getDadesCarrec(id) {
        if(!(id==null || id=='undefined')){
          router.push({ path: '/dadesCarrec/'+id })
        }
      }

      function getDadesPersona(idGauss){
        if(!(idGauss==null || idGauss=='undefined')){
          router.push({ path: '/dadesPersona/'+idGauss })
        }
      }

      return {
        carrec,
        idioma,
        getDadesCarrec,
        getDadesPersona
      };
  },

  computed: {
    styles_llista_carrecs() {
      if(this.$i18n.locale=='Castellano'){
        return 'llista-carrecs-castella';
      }
      else if(this.$i18n.locale=='Català'){
        return 'llista-carrecs-catala'
      }
      return 'llista-carrecs-angles'
    }       
  }  

};
</script>