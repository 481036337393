<template>
  <div>
    <div class="fonsBlanc_light">
      <div class="container" style="margin-top: 0px;">
        <div class="col-lg-12">
          {{$t('__sou_a__')}}: <a class="breadcrumb" @click="redireccionInicio()">{{$t('Inici')}}</a>&nbsp;>&nbsp;{{$t('Resultats de la cerca')}}
          <div v-if="avisCerca" style="margin-top:20px;">
            <h3>{{$t('__avis_cerca__')}}</h3>
          </div>
          <div v-if="data?.numFound==-1 && !avisCerca" style="margin-top:20px;">
            <h3>{{$t('__caracters_insuficients__')}}</h3>
          </div>        
          <div v-if="data?.numFound==0 && !avisCerca" style="margin-top:20px;">
            <h3>{{$t('__sense_coincidencies__')}}</h3>
          </div>         
          <div v-if="(data?.numFound>limitResults) && !avisCerca" style="margin-top:20px;">
            <h3>{{getText($t('__exces_coincidencies__'))}}</h3>
          </div>                
          <div v-if="(data?.numFound>0) && (data?.numFound<limitResults) && (!avisCerca)"> 
            <LlistaPersones v-if="(data?.numFound)>0" 
              paginacio=false
              :idioma-pagina="idioma" 
              :persones="data"/>
          </div>
          
          <div v-if="(data?.numFound>0) && (data?.numFound<limitResults) && (!avisCerca)">
            <br>
            <el-pagination
            :hide-on-single-page="ocultarPaginacio"
            @current-change="handleCurrentChange"
            v-model:current-page="paginaActual"
            background layout="prev, pager, next" 
            :total="totalResults" />
          </div>  
        </div>
      </div>
    </div>
    <PeuPagina v-if="endFetch"/>
  </div>


  
</template>

<script>

const idiomas = {English:"EN",Castellano:"ES",Català:"CA"};

import LlistaPersones from "@/components/LlistaPersones.vue"
import PeuPagina from "@/components/PeuPagina.vue"

export default{
  
  components:{
    LlistaPersones,
    PeuPagina   
  },

  data: function(){
    return{
      backendURL: process.env.VUE_APP_BACKEND_URL,
      apiURL: 'api',
      data: [],
      cerca: this.$route.params.pernr,
      searchField : this.$route.query.searchField,
      tipusCerca: "PERSONA",
      idioma: idiomas[this.$i18n.locale], //Utilizado para el idioma de los datos cargados de solr,
      totalResults: 0,
      limitResults: 300,
      avisCerca: false,
      //Parámetros paginación SOLR
      ocultarPaginacio: true, //Cuando sólo hay una página no mostrar paginación
      paginaActual: 1,
      rows: 10,
      offset: 0,
      endFetch: false
      
    }
 
  },
  watch: { 
    '$route.query.searchField': {
      //handler: function(search) {
      handler: function() {
        this.offset = 0;
        this.paginaActual = 1;
        if(this.$route.query.searchField===""){
          this.avisCerca = true
          this.endFetch = true
        }else{
          this.avisCerca = false
          this.fetchData();
        }        
      },
      deep: true,
      immediate: true
    },
    '$i18n.locale': {
      //handler: function(search) {
      handler: function() {
        this.cambiarIdiomaVariables()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    getText(text){
      return text.replace("__limitResults__", this.limitResults)
    },
    handleCurrentChange(numPagina) {
      window.scrollTo(0, 0)
      this.offset = (numPagina-1)*this.rows;
      this.fetchSolrData()
    },
    redireccionInicio(){
      this.$router.push({ path: '/' })
    },
    cambiarIdiomaVariables() {
      this.idioma = idiomas[this.$i18n.locale]
    },    

    fetchData: async function () {
      try {
        await Promise.all([
          this.fetchSolrData()
        ])
      } catch (error) {
        console.log(error)
      }
    },

    fetchSolrData: function () {      
      fetch(`${this.backendURL}/${this.apiURL}/persones?searchField=${this.$route.query.searchField}&tipusCerca=${this.tipusCerca}&idioma=${this.idioma}&offset=${this.offset}&max=${this.rows}`)
          .then(r => r.json())
          .then(json => {
            this.data=null
            if(json?.response){
              this.data = json.response
              this.totalResults = this.data.numFound

              //Redirección en caso de un único resultado
              if(this.totalResults==1){
                if(!(this.data.docs[0].idgauss_s===null)){
                  this.$router.push({ path: '/dadesPersona/'+this.data.docs[0].idgauss_s })
                  return
                }                       
              } 
            }
            
            if(this.data?.numFound>0){
              setTimeout(() => {this.endFetch = true;}, "50");
            }
            else{
              this.endFetch = true;
            }          
            
          })
          .catch(error => console.error('Error retrieving PERSONES LIST: ' + error))
    },

    getDadesPersona: function(idGauss){
      if(!(idGauss==null || idGauss=='undefined')){
        this.$router.push({ path: '/dadesPersona/'+idGauss })
      }             
    },   

    getDadesUnitat: function(id){
      if(!(id==null || id=='undefined')){
        this.$router.push({ path: '/dadesUnitat/'+id })
      }
    }

  },
  computed: {
    styles_llista_persones() {
      if(this.$i18n.locale=='Castellano'){
        return 'llista-persones-castella';
      }
      else if(this.$i18n.locale=='Català'){
        return 'llista-persones-catala'
      }
      return 'llista-persones-angles'
    }
  }

}
</script>
<style>
  .llista-persones-catala{
    margin-top:-20px;
    margin-left:150px;
  }
  .llista-persones-castella{
    margin-top:-20px;
    margin-left:150px;
  }
  .llista-persones-angles{
    margin-top:-20px;
    margin-left:85px;
  }
</style>