<template>
  <div>
    <div class="col-lg contenidorTitolSeccio">
      <div class="titolSeccio">{{$t('Carrecs')}}</div>
      <hr :class="styles_llista_carrecs">
    </div>
    
    <Carrec v-for="(carrec,index) in llistaCarrecs" 
    :key="carrec.id" 
    :idioma-pagina="idioma" 
    :dades-carrec="carrec"
    :class="(index % 2) == 0 ? 'fonsBlanc_light' : 'fonsGris_light'"/> 

  </div>


</template>
<script>
import { toRefs , computed } from "vue";

import Carrec from "@/components/Carrec.vue"

export default {
  components:{
    Carrec
  },
  name: "LlistaCarrecs",
  props: {
    carrecs: Object,
    idiomaPagina: String
  },

  setup(props) {
      const { idiomaPagina , carrecs } = toRefs(props);

      const idioma = computed(() => {
          return idiomaPagina?.value
      });

      const llistaCarrecs = computed(() => {
        return carrecs?.value?.docs

      });

      const mostrarVeureMesResultats = computed(() => {
        return ((carrecs?.value?.numFound)>5)?true:false;
      });      

      return {
        llistaCarrecs,
        idioma,
        mostrarVeureMesResultats,      
      };
  },

  computed: {
    styles_llista_carrecs() {
      if(this.$i18n.locale=='Castellano'){
        return 'llista-carrecs-castella';
      }
      else if(this.$i18n.locale=='Català'){
        return 'llista-carrecs-catala'
      }
      return 'llista-carrecs-angles'
    }       
  }  

};
</script>