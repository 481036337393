<template>
  <div>
    <div class="fonsBlanc_light">
      <div class="container" style="margin-top: 0px;">
        <div class="col-lg-12">
          {{$t('__sou_a__')}}: <a class="breadcrumb" @click="redireccionInicio()">{{$t('Inici')}}</a>&nbsp;>&nbsp;{{$t('Resultats de la cerca')}}
          <div v-if="data" class="col-lg">
            <div v-if="data['descripcio'+idioma+'_ac']" class="col-lg contenidorTitolSeccio">
              <div class="titolSeccio" style="color:#000;">{{ data["descripcio"+idioma+"_ac"] }}</div>
              <hr style="margin-top:0px;margin-left:0px;" >
            </div>
            <div class="col-lg-9" style="margin-left:20px;margin-bottom:0px;margin-top:0px;">

              <div v-if="data['persona_ac']" class="col-lg" style="margin-bottom:5px;margin-top:0px;">
                  <a class="no-link link-level-one" @click="getDadesPersona(data.idgauss_s)" >
                    {{data.persona_ac}}                            
                  </a><br>
              </div>

              <div v-if="data['unitat'+idioma+'_ac']">
                <i class="bi bi-diagram-3 bi-gris" style="font-size:22px;"></i>
                <a class="no-link" @click="getDadesUnitat(data.unitat_s)" style="margin-left:10px;">{{data["unitat"+idioma+"_ac"]}}</a><br>
              </div>            

              <table v-if="data['edifici'+idioma+'_ac']">
                <row>
                  <td style="padding-left:0px;vertical-align:middle;">                
                    <i class="bi bi-geo-alt bi-gris" style="font-size:25px;margin-left: -2px;"/>
                  </td>
                  <td style="padding-left:4px;">
                    <div v-if="data['edifici'+idioma+'_ac']">
                      <span>&nbsp;{{data["edifici"+idioma+"_ac"] }}</span><br>	                 
                    </div>
                    <div v-if="data.adreca_ac">
                      <span>&nbsp;{{ data.adreca_ac }}</span><br> 
                    </div>
                    <div v-if="data.localitat_ac">
                      <span>&nbsp;{{data.localitat_ac }}</span><br>
                    </div>
                    <div v-if="data.codi_postal_txt">
                      <span>&nbsp;{{data.codi_postal_txt }}</span><br>
                    </div>  
                    <span>&nbsp;<a :href="'https://maps.app.goo.gl/'+data.urlmap_txt" target="_blank" class="no-link descripcioDades">{{$t('__google_maps__')}}</a><a :href="'https://maps.app.goo.gl/'+data.urlmap_txt" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a></span><br>                 
                  </td>
                </row>
              </table>

              <div v-if="data.correu_usuari_txt && data.correu_domini_txt" class="col-lg" style="margin-bottom:0px;margin-top:0px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="40" fill="rgb(158, 158, 158)" class="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                </svg>
                <span style="margin-left:15px; margin-right:-2px;">{{data.correu_usuari_txt}}</span>        
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentcolor" class="bi bi-at bi-gris" viewBox="0 0 16 16">
                  <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"/>
                </svg><span style="margin-left:-3px;">{{data.correu_domini_txt}}</span>
              </div>

              <div v-if="data.telefon_txt">
                <i class="bi bi-telephone bi-gris" style="font-size:18px"></i><span style="margin-left:15px;">{{ data.telefon_txt }}</span><br>
              </div>

            </div>  

          </div> 

          <div v-if="data==null" style="margin-top:20px;">            
            <h3>{{$t('__sense_coincidencies__')}}</h3>          
          </div>

        </div>
      </div>
    </div>
    <PeuPagina v-if="endFetch"/>
  </div>
</template>

<script>

const idiomas = {English:"EN",Castellano:"ES",Català:"CA"};
import PeuPagina from "@/components/PeuPagina.vue"
export default {
  components:{
    PeuPagina       
  },

  data: function () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      apiURL: 'api',
      data: [],
      idioma: idiomas[this.$i18n.locale], //Utilizado para el idioma de los datos cargados de solr,
      id: this.$route.params.id,
    }
  },

  created() {
    this.fetchData();
  },

  watch: { 
    '$i18n.locale': {
      handler: function() {
        this.cambiarIdiomaVariables()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    redireccionInicio(){
      this.$router.push({ path: '/' })
    },
    cambiarIdioma(idioma) {
      this.$i18n.locale = idioma
    },
    cambiarIdiomaVariables() {
      this.idioma = idiomas[this.$i18n.locale]
    },

    fetchData: async function () {
      try {
        await Promise.all([
          this.fetchSolrData()
        ])
      } catch (error) {
        console.log(error)
      }
    },

    fetchSolrData: function () {
      fetch(`${this.backendURL}/${this.apiURL}/carrecById?id=${this.id}`)
          .then(r => r.json())
          .then(json => {
            //console.log(json)

            if(json?.response?.numFound==0){
              this.data=null
            }
            else{
              this.data = json.response.docs[0]
            }

            //this.data = json.response.docs[0] 
            this.endFetch=true            
          })
          .catch(error => console.error('Error retrieving carrecById: ' + error))
    },
    getDadesPersona: function(idGauss){
      this.$router.push({ path: '/dadesPersona/'+idGauss })
    },
    getDadesUnitat: function(id){
      //alert("id"+id)
      this.$router.push({ path: '/dadesUnitat/'+id })
    },
  },
}
</script>

<style>

  .open{
    background-color: green;
  }

  .closed{
    background-color: red;
  }
  .bi-gris{
    color: rgb(158, 158, 158);
  }

</style>